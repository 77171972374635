import logo from './logo.svg';
import './App.css';
import LLMStreamComponent from './LLMStreamComponent'
import './i18n'; 

function App() {
  return (
    <div className="App">
      <LLMStreamComponent></LLMStreamComponent>
    </div>
  );
}

export default App;
