import React, { useState, useEffect, useRef } from 'react';
import { createParser } from 'eventsource-parser';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown

const LLMStreamComponent = () => {
  const { t } = useTranslation(); // Use the translation hook
  const [topic, setTopic] = useState('');
  const [responses, setResponses] = useState([]); // Store multiple responses including inputs
  const [isStreaming, setIsStreaming] = useState(false); // Track if streaming is in progress
  const responseContainerRef = useRef(null);
  const abortControllerRef = useRef(null); // Ref to hold the AbortController
  const textareaRef = useRef(null); // Ref to the textarea

  // Generate a random session ID
  const generateSessionId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const sessionId = useRef(generateSessionId()); // Use a ref to keep the session ID constant across renders

  const scrollToBottom = () => {
    if (responseContainerRef.current) {
      window.scrollTo({
        top: responseContainerRef.current.scrollHeight,
        behavior: 'smooth' // You can use 'auto' or 'smooth' for scrolling behavior
      });
    }
  };

  useEffect(() => {
    // Scroll to the bottom whenever the responses change
    scrollToBottom();
  }, [responses]);

  const handleStream = async () => {
    setIsStreaming(true); // Indicate that streaming has started

    const abortController = new AbortController(); // Create a new AbortController
    abortControllerRef.current = abortController; // Store it in the ref

    let currentResponse = '';
    try {
      const responseStream = await fetch('/api/poem/stream', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input: {
            input: topic,
          },
          config: { 'configurable': { 'session_id': sessionId.current } },
          kwargs: {},
        }),
        signal: abortController.signal, // Attach the AbortController signal
      });

      const reader = responseStream.body.getReader();
      const decoder = new TextDecoder();

      const parser = createParser((event) => {
        if (event.type === 'event' && event.event === 'data') {
          const data = JSON.parse(event.data);
          if (data.content) {
            currentResponse += data.content;
            setResponses((prev) => {
              const updatedResponses = [...prev];
              updatedResponses[updatedResponses.length - 1].response = currentResponse;
              return updatedResponses;
            });
            scrollToBottom(); // Scroll to bottom as each chunk is received
          }
        }
      });

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        parser.feed(chunk);
      }

      setIsStreaming(false); // Indicate that streaming has finished
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Streaming aborted');
      } else {
        console.error('Error during stream:', error);
      }
      setIsStreaming(false); // Indicate that streaming has finished
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (topic.trim() === '') return;
    setTopic(''); // Clear the input field

    // Add the input topic to the flow
    setResponses((prev) => [
      ...prev,
      { input: topic, response: '' },
    ]);

    handleStream();

    // Focus back on the textarea after submitting
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleStop = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the fetch request
    }
  };

  const handleKeyDown = (e) => {
    if (e.altKey && e.key === 'Enter') {
      e.preventDefault(); // Prevent the default behavior (e.g., adding a new line)
      handleSubmit(e); // Trigger the form submission
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen p-4">
      <div
        ref={responseContainerRef}
        className="flex-1 w-full max-w-2xl"
        style={{
          overflowY: 'auto', // Enable vertical scrolling
          paddingBottom: '80px', // Add padding at the bottom to ensure content doesn't overlap with the input
        }}
      >
        {responses.map((entry, index) => (
          <div key={index} className="w-full">
            {/* Display the user's input on the right */}
            <div
              className="bg-blue-200 p-4 rounded text-left mt-2 max-w-full"
              style={{
                maxWidth: '750px',
                textAlign: 'right',
                marginLeft: 'auto', // Align to the right
                wordWrap: 'break-word', // Force word wrapping for long words
                overflowWrap: 'break-word', // Ensure text wrapping in all cases
                wordBreak: 'break-word', // Ensure text wraps even in difficult cases like URLs
              }}
            >
              <strong>{t('you')}:</strong> {entry.input}
            </div>
            {/* Display the corresponding response on the left with AI icon */}
            <div
              className="flex items-start bg-slate-500 p-4 rounded text-left mt-2 max-w-full"
              style={{
                maxWidth: '750px',
                wordWrap: 'break-word', // Force word wrapping for long words
                overflowWrap: 'break-word', // Ensure text wrapping in all cases
                wordBreak: 'break-word', // Ensure text wraps even in difficult cases like URLs
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/ai.svg`}
                alt="AI"
                style={{ width: '24px', height: '24px', marginRight: '8px' }} // Adjust the size and spacing as needed
              />
              <div style={{ flex: 1 }}>
                <ReactMarkdown>
                  {isStreaming && index === responses.length - 1 ? entry.response + ' ⬤' : entry.response}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="fixed bottom-0 w-full bg-white p-4"
        style={{
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Add shadow for better separation
          zIndex: 10, // Ensure the input stays on top
        }}
      >
        <form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto">
          <div className="flex">
            <textarea
              ref={textareaRef} // Reference to the textarea element
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              onKeyDown={handleKeyDown} // Handle key down event to detect Alt + Enter
              placeholder={t('enter_topic')}
              className="border p-2 rounded flex-grow resize-none"
              rows={1} // Start with a single line
              style={{ overflowY: 'auto' }} // Allow for auto-expansion as more lines are added
              // disabled={isStreaming} // Disable input while streaming
            />
            <button
              type="button" // Changed to "button" to prevent form submission on stop
              onClick={isStreaming ? handleStop : handleSubmit}
              className="ml-2 p-2 rounded"
              style={{ background: 'none', border: 'none', padding: 0 }} // Remove default button styles
            >
              <img
                src={`${process.env.PUBLIC_URL}/${isStreaming ? 'stop.svg' : 'play.svg'}`}
                alt={t(isStreaming ? 'stop' : 'submit')}
                style={{ width: '24px', height: '24px' }} // Adjust the size as needed
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LLMStreamComponent;