// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Language resources
const resources = {
  en: {
    translation: {
      "enter_topic": "Enter a topic",
      "submit": "Submit",
      "stop": "Stop",
      "run_id": "Run ID",
      "you": "You",
    }
  },
  es: {
    translation: {
      "enter_topic": "Ingresa un tema",
      "submit": "Enviar",
      "stop": "Detener",
      "run_id": "ID de ejecución",
      "you": "Tú",
    }
  },
  zh: {
    translation: {
      "enter_topic": "输入问题",
      "submit": "提交",
      "stop": "停止",
      "run_id": "run",
      "you": "用户",
    }
  },
  // Add more languages here
};

// Get the browser's language setting
const browserLanguage = navigator.language.split('-')[0]; // Get the language code (e.g., 'en' from 'en-US')

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: resources[browserLanguage] ? browserLanguage : 'en', // Use browser language if available, fallback to 'en'
    fallbackLng: 'en', // Fallback language if the current language is not available
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;